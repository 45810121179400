@import url("https://fonts.googleapis.com/css?family=Jost:400,700,500");

// Font Variables
$jost: 'Jost';

.jumboFourCards {
  align-items: center;
  display: flex;
  width: 100%;
  font-family: $jost;
  color: white;
  position: relative;
  background:none !important;
}


  .overlap-group {
    height: 936px;
    position: relative;
    width: 1340px;
  }
  
  
