.image-2 {
    mix-blend-mode: normal;
    width: 113px;
    position: absolute;
}
.why-enspire-tec-ologies-section-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width:100%;
    
}
.why-enspire-tec-ologies-section {
    align-items: center;
    display: flex;
    margin-top: 80px;
    background: none;
    color:white;
}