
.get-demo-button{
    position: relative;
    font-weight: bold;
    color: white;
    font-size: 25px;
    padding: 16px 40px;
  }
  .rectangle-520-1 {
    margin-left: 14px;  
    max-width: 15px;
  }
  .button {
    margin-top: 2rem;
    mix-blend-mode: normal;
    background: linear-gradient(97.35deg, #EB9C17 0%, #EF8157 98.28%);
    border-color:#ee8944;
  }