@import url("https://fonts.googleapis.com/css?family=Jost:400,700,500");

// Font Variables
$jost: 'Jost';

.jumboImage {
  font-family: $jost;
  color: white;
  background:none !important;
  position: relative;
}
.left-bg-stripe{
  height: 171px;
  position: absolute;
  top: 208px;
}

.description {
  margin-top: 24px;
  margin-bottom: 24px;
}

  .rectangle-531 {
    left: 0;
    position: relative;
    mix-blend-mode: normal;
    object-fit: cover;
  }
  .subtitle {
    font-weight: 500;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin-top: 24px;
  }