@import url("https://fonts.googleapis.com/css?family=Jost:400,700,500");

// Font Variables
$jost: 'Jost';

.jumboFourCards {
    align-items: flex-start;
    display: flex;
    width: 100%;
    font-family: $jost;
    color: white;
    position: relative;
    background:none !important;
    flex-direction: column;
  }