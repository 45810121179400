.footer2{
  color:#fff;
  background-color: #171c1c;
  display: block;
  mix-blend-mode: normal;
  width:100%;
  padding-top: 2rem;
}
.footer-col{
    font-size:18px;
}
.footer-seo{
    margin-top:1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.footer-col-mobile{
    justify-content: center;
}
.footer-logo{
    display:inline;
}