.contact-page {
	display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
	padding-top:5rem;
	padding-bottom:5rem;
	min-height:100vh;
}

