@import url("https://fonts.googleapis.com/css?family=Jost:400,700,500");

// Font Variables
$jost: 'Jost';

a, a:visited, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}
.logo{
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
}
.nav-bar {
  color:#fff;
  font-family: $jost;
  background-color: #1b1b1b;
  display: block;
  mix-blend-mode: normal;
  width:100%
}
.nav-item:global(.active) {
  background-color: #fff;
}
.call-to-action-button {
  border: 1px solid #353939 !important;
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 55px;
  justify-content: center;
  margin-left: 38px;
  padding: 0 22px;
}
.menu-item {
  letter-spacing: 0;
  margin-left: 38px;
  mix-blend-mode: normal;
  color:white !important;
}
.main-menu {
  justify-content: flex-end;
  font-weight: 700;
  font-size: 16px;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  margin-left: auto;
  color: #8B8D8D;

}
.place {
  letter-spacing: 0;
  margin-left: 38px;
  mix-blend-mode: normal;
}
.text-1 {
  letter-spacing: 0;
  margin-left: 38px;
  mix-blend-mode: normal;
}
.text-2 {
  letter-spacing: 0;
  margin-left: 38px;
  mix-blend-mode: normal;
}
.text-3 {
  letter-spacing: 0;
  margin-left: 38px;
  mix-blend-mode: normal;
}
.text-4 {
  letter-spacing: 0;
  mix-blend-mode: normal;
  color:white !important;
}
.enspire-technologies-logo-01-h {
  mix-blend-mode: normal;
  object-fit: contain;
  max-width: 100px;
  max-height: 100px;
}
.enspire-technologies-logo-01-4 {
  mix-blend-mode: normal;
  object-fit: contain;
  max-width: 61px;
  max-height: 66px;
}
.navbar-toggler{
  background-color:#ee8944;
}