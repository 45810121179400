.cardFront{
    position: absolute;
    color: black;
    max-width: 250px;
}
.panup{
    left: 0;
    position: relative;
    mix-blend-mode: normal;
    object-fit: cover;
    width:100%;
}