.recipe-card{
    right: 0vh;
    top: -35%;
    color:black;
    max-height: 1200px;
}
.recipe-card-flair{
    right: 20%;
    top: -45%;
    position: absolute;
    z-index: 1;
}