.cloud{
    position: absolute;
    filter:grayscale(100%);
}
.binary{
    max-width:100%;
    position: relative;
    justify-content: center;
    display: flex;
}
.image-flair{
    right: 24%;
    top: -20%;
    position: absolute;
    z-index: 1;
}
.description{
    padding-bottom: 2rem;
}
