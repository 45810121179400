@import url("https://fonts.googleapis.com/css?family=Jost:400,700,500");

// Font Variables
$jost: 'Jost';

.hero-section{
  padding-top: 15vh;
  color:white;
  margin-bottom: 8rem;
  min-height:100vh;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.hero-section-details {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  position: relative;
}
.hero-container{
  background-image: url('../../img/bg.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.hero-flair{
  max-height:80px;
}
.text-6 {
  color: white;
  font-family: $jost;
  max-width: 562px;
  font-weight: 700;
  letter-spacing: 0;
  margin-top: 72px;
  margin-bottom: 72px;
  mix-blend-mode: normal;
}
.get-demo-button-1 {
  align-items: center;
  background-size: 100% 100%;
  border-radius: 4px;
  display: flex;
  height: 58px;
  justify-content: flex-end;
  margin-top: 72px;
  mix-blend-mode: normal;
  padding: 0 39.6px;
}