.rectangle-530 {
    mix-blend-mode: normal;
    max-width:70px;
    width: 100%;
    min-width: 20px;
    left:180px;
    position: absolute;
}

.text-8 {
    letter-spacing: 0;
    mix-blend-mode: normal;
    font-weight: 700;
}
.title {
    font-size: 56px;
    font-weight: bold;
    position: relative;
}
.header-accent{
    padding:0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
