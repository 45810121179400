@import url("https://fonts.googleapis.com/css?family=Jost:400,700,500");
@import "~bootstrap/scss/bootstrap";

// Font Variables
$jost: 'Jost';

body{
  font-family: $jost;
}
h1, h2, h3, h4, h5 {
  font-weight: bold;
}

/* Extra Extra Small devices xxs (Phones, 450px and down) -- this would display the text at 5.0rem on phones */

@media only screen and (max-width: 450px) {
  h1 {
    font-size: 1.7rem; 
  }
  h3{
    font-size: 1rem;
  }
  h4{
    font-size: 1rem;
    font-weight: normal !important;
  }
  .mask-group {
    right: 10%;
    top: -20%;
    position: absolute;
    z-index: 1;
    max-width:10vh;
  }
  .hero-flair{
    max-width:30px;
    top:0px;
    position: absolute;
  }
  .h1flair{
    max-width: 20px;
    top: -5px;
    left: -10px;
    position: absolute;
  }
  .cloud{
    display: none;
  }
  .profile{
    flex-direction: column;
  }
  .orangesquare{
    display: none;
  }
  .text-12 {
    display: none !important;
  }
  .image-left{
    display: none;
  }
  h2{
    font-size: 1.5rem;
    font-weight: bold;
  }
  .orangesquare{
    max-width: 50px;
  }
  .footer-col{
    display: none;
  }
  .footer-seo{
    display: flex;
    flex-direction: column;
  }
  .footer-container{
    display:flex;
    flex-direction: row;
    text-align: center;
  }
  .footer-col-mobile{
    display: flex;
  }
  .mask-group-2 {
    display: none;
  }
  .cardFront{
    right:0% ;
    top:-20%; 
  }
}

/* Extra Small devices xs (Phones, 451pxand up) -- this would display the text at 5.0rem on phones */

@media only screen and (min-width: 451px) {
  h1 {
      font-size: 2rem; // you can also use px here...
  }
  h2{
    font-size: 1.5rem;
    font-weight: bold;
  }
  h4{
    font-size: 1rem;
    font-weight: normal !important;
  }
  .h1flair{
    max-width: 25px;
    top: -5px;
    left: -10px;
    position: absolute;
  }
  .image-left{
    display: none;
  }
  .mask-group {
    right: 5%;
    top: -10%;
    position: absolute;
    z-index: 1;
    max-width:20vh;
  }
  p{
    font-size: 1rem;
  }
  .orangesquare{
    display: none;
  }
  .cloud{
    display: none;
  }
  .hero-flair{
    max-width:40px;
    position: absolute;
  }
  .rectangle-530 {
    max-width: 20px;
  }
  .footer-col{
    display: none;
}
  .navbar-brand{
    font-size:.8rem !important;
  }
  .footer-seo{
    display: flex;
    flex-direction: column ;
    justify-content: center;
  }
  .footer2{
    flex-direction: column !important;
  }
  .footer-container{
    display:flex;
    flex-direction: column;
    text-align: center;
  }
  .footer-col-mobile{
    display:flex;
    text-align: center;
  }
  .overlap-group2 {
    padding: 30px 50px 30px 50px !important;
  }

  .text-12 {
    display: none !important;
  }

  .get-demo-button{
    font-size: 16px !important;
    padding: 0px 0px !important;
  }

  .description{
    font-size: 0.9rem !important;
  }

  .mask-group {
    max-width: 30px;
  }

  h4{
    font-size: 1.2rem;
  }
  h5{
    font-size: 16px;
  }

  .get-demo-button-1 {
    margin-top: 2rem !important;
  }

  .hero-section {
    padding-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .card-deck{
    flex-direction: column;
  }
  .cardFront{
    right:0% ;
    top:-45%; 
  }
  .mask-group-2 {
    display: none;
  }
}


/* sm devices (for tablets - screens equal to or greater than 768px wide) -- This would display the text at 10.0rem on laptops and larger screens */

@media only screen and (min-width: 769px) {
  h1 {
      font-size: 3.0rem; // you can also use px here...
  }
  h4{
    font-size: 1.5rem;
    font-weight: normal !important;
  }
  .h1flair{
    max-width: 40px;
    top: 10px;
    left: -25px;
    position: absolute;
  }
  .cardFront{
    right:10% ;
    top:-20%;
  }
  .hero-flair{
    max-width:50px;
    position: absolute;
  }
  .mask-group-2 {
    display: flex;
    right: 2%;
    top: -10%;
    position: absolute;
    z-index: 1;
    max-width:20vh;
  }
  h2 {
    font-size: 2.0rem; // you can also use px here...
}
h5{
  font-size: 16px;
}
.card-deck{
  flex-direction: column;
}
.image-left{
  display: none;
}
.orangesquare{
  max-width: 50px;
  display:flex;
}
.cloud{
  top: 13%;
  right: -5%;
  max-width: 250px;
  display: flex;
}
.footer-col{
  display: none;
}
.footer-seo{
  display: flex;
  flex-direction: column;
}
.footer-container{
  display:flex;
  flex-direction: row;
  text-align: center;
}
.footer-col-mobile{
  display: flex;
}
  .mask-group {
    right: 5%;
    top: -10%;
    position: absolute;
    z-index: 1;
    max-width:20vh;
  }
  .rectangle-530 {
    max-width: 20px;
  }
  .rectangle-531 {
    width: 100%;
  }
  .overlap-group2 {
    padding: 50px 80px 50px 80px !important;
  }
  .text-12 {
    font-weight: bold !important;
    font-size: 0.8rem !important;
    margin-top: 0px !important;
  }

}
/* Medium md (for small laptops - screens equal to or greater than 992px wide) */
@media only screen and (min-width:992px){
  h1 {
    font-size: 4rem; // you can also use px here...
  }
  h4{
    font-size: 1.5rem;
    font-weight: normal !important;
  }
  .mask-group-2 {
    display: flex;
    right: 5%;
  }
  .hero-flair{
    max-width:70px;
    position: absolute;
  }
  .cloud{
    top:-13%;
    right: 5%;
    max-width: 400px;
  }
  .footer-col{
    display: flex;
  }
  .footer-seo{
    display: flex;
    flex-direction: row;
  }
  .footer-col-mobile{
    display: none;
  }
  .footer-logo{
    display: flex !important;
  }
  .image-left{
    display:flow-root;
  }
  .card-deck{
    flex-direction: row;
  }
}

/* Large lg (for laptops and desktops - screens equal to or greater than 1200px wide) */
@media only screen and (min-width:1200px){
  .cloud{
    top:-17%;
    max-width: 400px;
  }
  .text-6 {
    max-width: 600px !important;
  }
  .footer-col-mobile{
    display: none;
  }
  .footer-col{
    display: flex;
  }
  .footer-logo{
    display: flex !important;
  }
}

/* Extra Large devices (laptops/desktops, 1608px and up) */
@media only screen and (min-width: 1608px) {
  h1 {
      font-size: 70px; // you can also use px here...
  }
  .mask-group {
    right: 5%;
    top: -10%;
    position: absolute;
    z-index: 1;
    max-width:50vh;
  }
  .text-6 {
    max-width: 600px !important;
  }
  .cloud{
    top:-25%;
    max-width: 400px;
  }

  .container{
    max-width: 1400px;
  }
  .footer-logo{
    display: flex !important;
  }

  .overlap-group2 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .h1flair{
    max-width: 70px;
    min-width:20px;
    min-height:70px;
    position: absolute;
    margin-left: -5%;
  }
  
}
.subtitle{
  /* Heading / H4 / 24 Bold */
  font-family: $jost;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  height: auto;
  /* identical to box height */
  /* Primary / Orange */

  color: #EE8F39;
}
.benefits-cards {
    align-items: flex-start;
    display: flex;
    height: 664px;
    margin-top: 56px;
    min-width: 1227px;
    position: relative;
  }
  .blogs {
    align-items: flex-start;
    display: flex;
    height: 470px;
    min-width: 1327px;
    position: relative;
  }
  .border-bottom {
    align-items: flex-start;
    display: flex;
    margin-top: 64px;
    min-width: 1610px;
  }
  .cloud-migration-blog {
    align-items: flex-end;
    display: flex;
    margin-top: 64px;
    min-width: 1340px;
    mix-blend-mode: normal;
  }
  .cloud-migration-blog-details {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 534px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1610px;
  }
  .cloud-migration-section {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    min-height: 808px;
    padding: 24px 0;
    width: 1452px;
  }
  .footer {
    align-items: center;
    margin-top: 80px;
    mix-blend-mode: normal;
    width: 100%;
  }
  .frame-97602 {
    align-items: flex-start;
    display: flex;
    height: 162px;
    min-width: 430px;
  }
  .frame-97638 {
    align-items: flex-start;
    display: flex;
    height: 234px;
    min-width: 1340px;
  }
  .frame-97639 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 849px;
    position: relative;
    width: 100%;
  }
  .frame-97640 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 208px;
    min-height: 5077px;
    position: relative;
    width: 100%;
  }
  .get-connected-section {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    min-height: 234px;
    width: 1106px;
  }
  .get-connected-section-cards {
    align-items: flex-start;
    display: flex;
    height: 363px;
    margin-top: 64px;
    min-width: 1340px;
    position: relative;
  }
  .home-page {
    background-color: #0b0b0b;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-family: $jost;
  }
  .line-13 {
    height: 1px;
    margin-top: -0.5px;
    width: 1270px;
  }
  .line-14 {
    height: 2px;
    margin-top: -1.0px;
    width: 340px;
  }
  .mask-group-1 {
    height: 170px;
    margin-left: 64px;
    margin-top: 0;
    position: absolute;
    right:0;
  }
  .overlap-group-1 {
    height: 534px;
    margin-bottom: 0;
    position: relative;
    width: 1610px;
  }
  .rectangle-530-1 {
    height: 48px;
    margin-left: -3px;
    margin-top: -3.75px;
    mix-blend-mode: normal;
    width: 48px;
  }
  .right-strategy-es-shadow-right {
    height: 534px;
    left: 1041px;
    mix-blend-mode: normal;
    position: absolute;
    top: 0;
    width: 287px;
  }
  .right-strategy-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    min-height: 720px;
    padding: 24px 0;
    width: 100%;
  }
  .right-strategy-title {
    align-items: flex-start;
    display: flex;
    height: 162px;
    min-width: 1340px;
  }
  .text-10 {
    letter-spacing: 0;
    margin-bottom: 4rem;
    mix-blend-mode: normal;
  }
  .text-14 {
    letter-spacing: 0;
    margin-left: 11px;
    min-height: 81px;
    mix-blend-mode: normal;
  }
  .text-15 {
    color: var(--jaffa);
    font-family: $jost;
    font-size: var(--font-size-xl2);
    font-weight: 700;
    letter-spacing: 0;
    margin-right: -2px;
    margin-top: 20px;
    min-height: 35px;
    min-width: 1004px;
    mix-blend-mode: normal;
    width: 1004px;
  }
  .text-16 {
    letter-spacing: 0;
    margin-right: -2px;
    margin-top: 20px;
    min-height: 78px;
    min-width: 1004px;
    mix-blend-mode: normal;
    opacity: 0.8;
    width: 1004px;
  }
  .title-2 {
    align-items: flex-start;
    display: flex;
    height: 81px;
    min-width: 1106px;
  }
  .place-1 {
    letter-spacing: 0;
    min-height: 23px;
    min-width: 47px;
    mix-blend-mode: normal;
  }
  .rectangle-520-2 {
    height: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    mix-blend-mode: normal;
    width: 14px;
  }
  .text-13 {
    color: var(--burnt-sienna);
    font-family: $jost;
    font-size: var(--font-size-m2);
    font-weight: 700;
    letter-spacing: 0;
    min-height: 26px;
    min-width: 87px;
    mix-blend-mode: normal;
  }
  .get-the-integration {
    letter-spacing: 0;
    margin-top: 16px;
    min-height: 69px;
    min-width: 221px;
    mix-blend-mode: normal;
    width: 221px;
  }
  .right-strategy-component {
    align-items: flex-end;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-height: 363px;
    mix-blend-mode: normal;
    padding: 32px 30px;
    width: 283px;
  }
  .right-strategy-component-1.right-strategy-component {
    margin-left: 32px;
    position: relative;
  }
  .text-17 {
    letter-spacing: 0;
    min-height: 76px;
    min-width: 221px;
    mix-blend-mode: normal;
    width: 221px;
  }
  .dell-boomi-connect-n {
    letter-spacing: 0;
    min-height: 38px;
    min-width: 221px;
    mix-blend-mode: normal;
    width: 221px;
  }
  .get-the-integration-1 {
    letter-spacing: 0;
    margin-top: 16px;
    min-height: 207px;
    min-width: 221px;
    mix-blend-mode: normal;
    width: 221px;
  }
  .right-strategy-component-2 {
    align-items: flex-end;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    min-height: 363px;
    mix-blend-mode: normal;
    padding: 32px 30px;
    width: 283px;
  }
  .column01 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 407px;
    width: 380px;
  }
  .frame-97611 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    min-height: 185px;
    width: 380px;
  }
  .frame-97612 {
    align-items: flex-start;
    display: flex;
    height: 206px;
    min-width: 380px;
    mix-blend-mode: normal;
  }
  .harness-the-power-of {
    letter-spacing: 0;
    margin-top: 15px;
    min-height: 115px;
    mix-blend-mode: normal;
    width: 382px;
  }
  .line-12 {
    height: 2px;
    margin-top: 15px;
    width: 104px;
  }
  .overlap-group1-1 {
    height: 207px;
    margin-top: -0.4px;
    position: relative;
    width: 380px;
  }
  .rectangle-521 {
    height: 23px;
    left: 40px;
    mix-blend-mode: normal;
    position: absolute;
    top: 0;
    width: 22px;
  }
  .rectangle-533 {
    height: 197px;
    left: 0;
    mix-blend-mode: normal;
    position: absolute;
    top: 10px;
    width: 380px;
  }
  .text-19 {
    letter-spacing: 0;
    min-height: 38px;
    mix-blend-mode: normal;
    width: 382px;
  }
  .amazon-web-services {
    letter-spacing: 0;
    min-height: 76px;
    mix-blend-mode: normal;
    width: 382px;
  }
  .column02 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    min-height: 422px;
    position: relative;
    width: 380px;
  }
  .frame-97611-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    min-height: 200px;
    width: 380px;
  }
  .harness-the-power-of-1 {
    letter-spacing: 0;
    margin-top: 15px;
    min-height: 92px;
    mix-blend-mode: normal;
    width: 382px;
  }
  .line-12-1 {
    height: 1px;
    margin-top: 15px;
    width: 104px;
  }
  .frame-97613 {
    align-items: flex-start;
    display: flex;
    min-width: 380px;
    mix-blend-mode: normal;
  }
  .frame-97613-1.frame-97613 {
    height: 206px;
    position: relative;
  }
  .overlap-group1-2 {
    height: 206px;
    position: relative;
    width: 380px;
  }
  .rectangle-521-1 {
    height: 56px;
    left: 40px;
    mix-blend-mode: normal;
    position: absolute;
    top: 0;
    width: 56px;
  }
  .rectangle-533-1 {
    height: 196px;
    left: 0;
    mix-blend-mode: normal;
    object-fit: cover;
    position: absolute;
    top: 10px;
    width: 380px;
  }
  .amazon-web-services-1 {
    letter-spacing: 0;
    min-height: 114px;
    mix-blend-mode: normal;
    width: 382px;
  }
  .column03 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    min-height: 437px;
    position: relative;
    width: 380px;
  }
  .frame-97611-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    min-height: 215px;
    width: 380px;
  }
  .harness-the-power-of-2 {
    letter-spacing: 0;
    margin-top: 15px;
    min-height: 69px;
    mix-blend-mode: normal;
    width: 382px;
  }
  .line-12-2 {
    height: 1px;
    margin-top: 15px;
    width: 104px;
  }
  .amazon-web-services-2 {
    letter-spacing: 0;
    line-height: 32px;
    min-height: 96px;
    mix-blend-mode: normal;
    width: 382px;
  }
  .column04 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    min-height: 470px;
    position: relative;
    width: 380px;
  }
  .frame-97611-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    min-height: 248px;
    width: 380px;
  }
  .harness-the-power-of-3 {
    letter-spacing: 0;
    line-height: 30px;
    margin-top: 15px;
    min-height: 120px;
    mix-blend-mode: normal;
    opacity: 0.8;
    width: 382px;
  }
  .line-12-3 {
    height: 1px;
    margin-top: 15px;
    width: 104px;
  }
  .benefits-card01 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 664px;
    position: relative;
    width: 283px;
  }
  .rectangle-10 {
    height: 240px;
    mix-blend-mode: normal;
    object-fit: cover;
    width: 280px;
  }
  .frame-97615 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    min-height: 184px;
    width: 283px;
  }
  .frame-97615-1.frame-97615 {
    position: relative;
  }
  .we-listen-to-you-to {
    letter-spacing: 0;
    margin-top: 16px;
    min-height: 92px;
    mix-blend-mode: normal;
    width: 285px;
  }
  .white-glove-service {
    letter-spacing: 0;
    min-height: 76px;
    mix-blend-mode: normal;
    width: 285px;
  }
  .benefits-card02 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    min-height: 552px;
    position: relative;
    width: 283px;
  }
  .benefits-card04.benefits-card02 {
    min-height: 575px;
  }
  .rectangle-10-1 {
    height: 240px;
    margin-left: -0.25px;
    mix-blend-mode: normal;
    object-fit: cover;
    width: 280px;
  }
  .benefits-card03 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    min-height: 478px;
    width: 283px;
  }
  .frame-97615-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    min-height: 222px;
    width: 283px;
  }
  .rectangle-10-2 {
    height: 240px;
    mix-blend-mode: normal;
    width: 280px;
  }
  .surname {
    letter-spacing: 0;
    min-height: 114px;
    mix-blend-mode: normal;
    width: 285px;
  }
  .we-listen-to-you-to-1 {
    letter-spacing: 0;
    margin-top: 16px;
    min-height: 92px;
    mix-blend-mode: normal;
    width: 285px;
  }
  .contact-us-1 {
    align-items: flex-start;
    display: flex;
    margin-top: 80px;
    min-width: 1228px;
    mix-blend-mode: normal;
  }
  .contact-us-for-a-demo {
    align-self: center;
    height: 47px;
    margin-left: 13px;
    width: 571px;
  }
  .frame-97602-1 {
    align-items: flex-start;
    display: flex;
    height: 81px;
    min-width: 709px;
  }
  .frame-97603-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 157px;
    width: 709px;
  }
  .overlap-group3 {
    align-items: center;
    background-size: 100% 100%;
    display: flex;
    height: 365px;
    min-width: 1216px;
    padding: 0 110px;
    position: relative;
  }
  .rectangle-530-2 {
    height: 48px;
    margin-left: -3px;
    margin-top: -3.75px;
    mix-blend-mode: normal;
    width: 48px;
  }
  .text-21 {
    letter-spacing: 0;
    margin-left: 64px;
    margin-top: 24px;
    min-height: 52px;
    mix-blend-mode: normal;
    opacity: 0.8;
    width: 431px;
  }
  
  @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
  * {
    box-sizing: border-box;
  }
  .container-center-horizontal {
    pointer-events: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .container-center-horizontal > * {
    pointer-events: auto;
    flex-shrink: 0;
  }
  .screen a {
    text-decoration: none;
    display: contents;
    color:white;
  }
  