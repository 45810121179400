@import url("https://fonts.googleapis.com/css?family=Jost:400,700,500");

// Font Variables
$jost: 'Jost';

.get-demo-button-2 {
    font-family: $jost;
    font-weight: bold;
    line-height: 26px;
    color:#EF8157 !important;
    background-color: white !important;
    border-radius: 4px;
  }
  .buttonalt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: flex-end;
    align-items: center;
    color:#EF8157 !important;
    min-width:190px;
  }
  .contact-us {
    align-items: flex-start;
    display: flex;
    margin-top: 80px;
    width: 100%;
    mix-blend-mode: normal;
  }
  .frame-97603 {
    font-family: $jost;
    font-weight: bold;
    color: white;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .overlap-group2 {
    color: white;
    align-items: flex-start;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    background-image: url("https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/black-orange-yes@1x.svg");
    margin-bottom: 5rem;
    padding:5vh;
  }
  .text-11 {
    font-size: 56px;
    font-weight: bold;
    letter-spacing: 0;
    mix-blend-mode: normal;
  }
  .text-12 {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0;
    margin-top: 24px;
    mix-blend-mode: normal;
    opacity: 0.8;
  }